<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenPanel.tea_factory_service') + ' ' + ($route.query.id ? $t('globalTrans.update') : $t('globalTrans.apply')) }}</h4>
      </template>
      <template v-slot:body>
          <b-overlay :show="isLoading">
            <b-row v-if="application.application?.status == 9 && application.application?.return_remarks">
                <b-col>
                    <div>
                    <b-alert show dismissible variant="danger">
                        <strong>{{ $t('teaGardenBtriService.return_remarks_note') }}</strong>: {{ application.application?.return_remarks }}
                    </b-alert>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <form-wizard shape="circle" color="#005B5B" title="" subtitle="" :start-index.sync="activeIndex">
                        <tab-content :title="$t('teaGardenPanel.applicant_tab')" icon="ri-user-location-fill" :before-change="tabSubmitApplicant">
                            <div class="application-itmes">
                                 <ApplicantForm @update-form-data="updateFormData" :key="application" :app_id="app_id" :applicantFormData="application" :isShow="dataLoaded" :baseUrl="baseUrl" :isRenew="false" ref="applicant_form"/>
                            </div>
                        </tab-content>
                        <tab-content :title="$t('teaGardenPanel.factory_tab')" icon="ri-building-3-fill" :before-change="tabFactoryInfo">
                            <div class="application-itmes">
                                 <FactoryInfo :key="key" :app_id="app_id" :factoryInfoData="application.factory_info" :allData="application" :isRenew="false" ref="factory_info_form"/>
                            </div>
                        </tab-content>
                        <tab-content :title="$t('teaGardenPanel.machineries_tab')" icon="ri-file-info-fill" :before-change="tabMachineriesInfo">
                            <div class="application-itmes">
                                 <MachineriesInfo :key="application" :app_id="app_id" :machineriesInfoData="application.machineries_info" :isRenew="false" ref="machineries_info_form"/>
                            </div>
                        </tab-content>
                        <tab-content v-if="hideAttach" :title="$t('teaGardenPanel.attachment_tab')" icon="ri-attachment-line" :before-change="tabAttachmentInfo">
                            <div class="application-itmes">
                                 <AttachmentInfo :key="application" :app_id="app_id" :attachmentInfoData="application.attachment_file" :attachmentAddMoreData="application.add_more_files" :allData="application" :draft="draft" :isRenew="false" ref="attachment_file_form"/>
                            </div>
                        </tab-content>
                        <template slot="footer" slot-scope="props">
                            <div class="wizard-footer-right">
                                <wizard-button class="btn btn-secondary" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle">{{ $t('globalTrans.back') }}</wizard-button>
                                <wizard-button
                                    type="submit"
                                    class="btn btn-primary ml-1 mr-1"
                                    title="Create Draft and Save!"
                                    v-if="application.application?.status !== 9"
                                    @click.native="props.nextTab(), draft = 1"
                                >
                                <i class="ri-draft-line"></i> {{ $route.query.route_from ? $t('globalTrans.save') : $t('globalTrans.save_draft') }}
                                </wizard-button>
                                <wizard-button
                                    type="submit"
                                    v-if="!props.isLastStep"
                                    @click.native="props.nextTab(), isNext = true"
                                    class="btn btn-success ml-1 mr-1"
                                    title="Draft and go to next!"
                                    :style="props.fillButtonStyle"
                                ><i class="ri-save-2-line"></i> {{ $route.query.route_from ? $t('globalTrans.save_n_next') : $t('globalTrans.draft_n_next') }}
                                </wizard-button>
                                <wizard-button
                                    v-else
                                    type="submit"
                                    class="btn btn-success ml-1"
                                    title="Submit!"
                                    @click.native="finalSave(), app_status = 2"
                                >
                                <i class="ri-send-plane-fill"></i> {{ $t('globalTrans.finalSave')}}
                                </wizard-button>
                                <wizard-button
                                    type="button"
                                    class="btn btn-danger ml-1 mr-1"
                                    title="Cancel"
                                >
                                    <router-link to="tea-factory-application" class="text-white"><i class="ri-close-circle-line"></i> {{ $t('globalTrans.cancel') }}</router-link>
                                </wizard-button>
                            </div>
                        </template>
                    </form-wizard>
                </b-col>
            </b-row>
          </b-overlay>
      </template>
    </body-card>
  </div>
</template>
<script>
// import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import BreadCumb from '@/components/BreadCumb.vue'
import ApplicantForm from './ApplicantForm'
import FactoryInfo from './FactoryInfo'
import MachineriesInfo from './MachineriesInfo'
import AttachmentInfo from './AttachmentInfo'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
import { mapGetters } from 'vuex'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { factoryInfoDetailsApi } from '../../api/routes'
export default {
  components: { BreadCumb, ApplicantForm, FactoryInfo, MachineriesInfo, AttachmentInfo, FormWizard, TabContent, WizardButton },
  name: 'Form',
  data () {
    return {
        baseUrl: teaGardenServiceBaseUrl,
        valid: null,
        saveBtnName: this.$t('globalTrans.update'),
        errors: [],
        step: 0,
        key: 1,
        activeIndex: 0,
        sectorId: 0,
        hideAttach: true,
        application: {
            factory_type_id: 0,
            applicant_type: 0,
            application_type: 1,
            garden_id: 0,
            app_id: 0,
            parent_id: null,
            user_id: 0,
            area_type_id: 0,
            division_id: 0,
            district_id: 0,
            city_corporation_id: 0,
            country_id: 1,
            paurashava_id: 0,
            upazila_id: 0,
            union_id: 0,
            factory_info: {
                btb_tea_factory_reg_main_id: 0,
                area_type_id: 0,
                division_id: 0,
                district_id: 0,
                country_id: 1,
                city_corporation_id: 0,
                paurashava_id: 0,
                upazila_id: 0,
                union_id: 0,
                processed_area: [],
                factory_name_en: null,
                factory_name_bn: null
            },
            machineries_info: [],
            attachment_file: {
                btb_tea_factory_reg_main_id: 0,
                app_id: 0
            },
            add_more_files: [
                { file_name_en: null, file_name_bn: null, file: null }
            ]
        },
        app_id: 0,
        app_status: 1,
        draft: 0,
        isLoading: false,
        dataLoaded: false
    }
  },
  watch: {
        'application.application_type': {
            handler (newVal, oldVal) {
                if (this.app_id === 0) {
                    if (newVal === 2) {
                     this.hideAttach = false
                    } else {
                        this.hideAttach = true
                    }
                }
            }
        }
   },
  created () {
    if (this.$route.query.id) {
        this.isLoading = true
        this.app_id = this.$route.query.id
        this.getTeaFactoryData()
    } else {
        this.isLoading = false
    }
    this.application.user_id = this.authUser.user_id
  },
  computed: {
    loading: function () {
        return this.$store.state.commonObj.loading
    },
    ...mapGetters({
        commonProfile: 'Auth/commonProfile',
        authUser: 'Auth/authUser'
    })
  },
  methods: {
    finalSave () {
        this.$swal({
            title: this.$t('globalTrans.final_save_msg'),
            showCancelButton: true,
            confirmButtonText: this.$t('globalTrans.yes'),
            cancelButtonText: this.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                if (this.hideAttach) {
                    this.tabAttachmentInfo()
                } else {
                    this.tabMachineriesInfo()
                }
            } else {
                this.app_status = 1
            }
        })
    },
    async getTeaFactoryData () {
        const result = await RestApi.getData(teaGardenServiceBaseUrl, factoryInfoDetailsApi, { app_id: this.app_id })
        if (result.success) {
            const data = result.data
            if (data) {
                this.application = data
                this.application.app_id = this.app_id
                if (parseInt(this.application.application.application_type) === 2) {
                    this.hideAttach = false
                } else {
                    this.hideAttach = true
                }
                  // check relational data
                if (!data.factory_info) {
                    this.application.factory_info = {
                        id: 0,
                        btb_tea_factory_reg_main_id: 0,
                        area_type_id: 0,
                        division_id: 0,
                        district_id: 0,
                        country_id: 1,
                        city_corporation_id: 0,
                        paurashava_id: 0,
                        upazila_id: 0,
                        union_id: 0,
                        processed_area: [],
                        factory_name_en: null,
                        factory_name_bn: null
                   }
                }
                if (!data.machineries_info) {
                    this.application.machineries_info = {
                        id: 0,
                        btb_tea_factory_reg_main_id: 0
                   }
                }
                if (!data.attachment_file) {
                    this.application.attachment_file = {
                        btb_tea_factory_reg_main_id: 0,
                        app_id: 0
                    }
                }
                if (!Object.keys(data.add_more_files).length) {
                    this.application.add_more_files = [
                        { file_name_en: null, file_name_bn: null, file: null }
                    ]
                }
                this.key++
            }
            this.isLoading = false
            this.dataLoaded = true
        } else {
            this.isLoading = false
        }
    },
    async tabSubmitApplicant () {
        const result = await this.$refs.applicant_form.submit()
        if (result.success) {
            if (this.draft) {
                if (this.$route.query.route_from) {
                    return this.$router.push({ name: this.$route.query.route_from })
                } else {
                    this.$router.push({ path: 'tea-factory-application' })
                }
            } else {
                this.app_id = result.data.id
                this.key++
                return true
            }
        }
    },
    async tabFactoryInfo () {
        const result = await this.$refs.factory_info_form.submit()
        if (result.success) {
            if (this.draft) {
                if (this.$route.query.route_from) {
                    return this.$router.push({ name: this.$route.query.route_from })
                } else {
                    this.$router.push({ path: 'tea-factory-application' })
                }
            } else {
                this.app_id = result.data.btb_tea_factory_reg_main_id
                return true
            }
        }
    },
    async tabMachineriesInfo () {
        const result = await this.$refs.machineries_info_form.submit()
        if (result.success) {
            if (this.draft || (this.application.application?.application_type === 2 && this.application.application?.status === 9)) {
                if (this.$route.query.route_from) {
                    return this.$router.push({ name: this.$route.query.route_from })
                } else {
                    this.$router.push({ path: 'tea-factory-application' })
                }
            } else {
                if (this.hideAttach) {
                    this.app_id = result.data.id
                    return true
                } else {
                    if (this.$route.query.route_from) {
                        return this.$router.push({ name: this.$route.query.route_from })
                    }
                    if (result.is_payment) {
                        const baseTableId = result.base_table_id
                        this.$router.push({ name: 'tea_garden_service.btb.payment', params: { id: baseTableId }, query: { from: 'tea_garden_service.btb.tea_factory_application' } })
                    } else {
                        this.$router.push({ name: 'tea_garden_service.btb.tea_factory_application' })
                    }
                }
            }
        }
    },
    async tabAttachmentInfo () {
        const result = await this.$refs.attachment_file_form.submit()
        if (result.success) {
            if (this.$route.query.route_from) {
                return this.$router.push({ name: this.$route.query.route_from })
            }
            if (this.draft || this.application.application?.status === 9) {
                this.$router.push({ path: 'tea-factory-application' })
            } else {
                if (result.is_payment) {
                    const baseTableId = result.base_table_id
                    this.$router.push({ name: 'tea_garden_service.btb.payment', params: { id: baseTableId }, query: { from: 'tea_garden_service.btb.tea_factory_application' } })
                } else {
                    this.$router.push({ name: 'tea_garden_service.btb.tea_factory_application' })
                }
            }
        }
    },
    updateFormData (data) {
        if (data) {
            if (data.parent_id) {
            // this.isRenew = true
            }
            this.application = Object.assign({}, this.application, data)
            this.application.manufacturing_methods = this.application.manufacturing_methods ?? []
        }
    }
  }
}
</script>
