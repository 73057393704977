<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.refund_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="12" sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      label-for="year"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        :options="yearList"
                        v-model="search.year"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label-for="fair_id"
                    >
                      <template v-slot:label>
                        {{ $t('tradeFairConfig.fair_name') }}
                      </template>
                      <b-form-select
                        disabled
                        plain
                        id="fair_id"
                        :options="dhakaFairList"
                        v-model="search.fair_id"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                      label-for="circular_type"
                    >
                      <template v-slot:label>
                        {{ $t('ditfTradeFairManages.circular_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="circular_type"
                        :options="circularTypeList"
                        disabled
                        v-model="search.circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="Stall Category" vid="stall_category" rules="" v-slot="{ errors }">
                    <b-form-group
                      label-for="stall_category"
                    >
                      <template v-slot:label>
                        {{ $t('fair_report.space_category') }}
                      </template>
                      <b-form-select
                        plain
                        id="stall_category"
                        :options="stallCategoryList"
                        v-model="search.stall_category"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="date"
            >
              <template v-slot:label>
                {{ $t('globalTrans.from_date') }}
              </template>
              <date-picker
                id="start_date"
                class="form-control"
                v-model="search.start_date"
                :placeholder="$t('globalTrans.select')"
              >
              </date-picker>
            </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-group
                      label-for="date"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.to_date') }}
                      </template>
                      <date-picker
                        id="end_date"
                        class="form-control"
                        v-model="search.end_date"
                        :placeholder="$t('globalTrans.select')"
                      >
                      </date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-button :disabled="isResult" type="submit" size="sm" variant="primary" class="mt-20">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fair_report.refund_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" size="sm" @click="pdfExport" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.refund_report') }}
          </list-report-head>
          <b-row class="mb-2">
            <b-col md="4">
              <span>{{ $t('globalTrans.year') }}</span>:
              <strong>{{ parseInt(search.year) ? $n(search.year, {useGrouping: false}) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="4" class="text-center">
              <span>{{ $t('ditfTradeFairManages.circular_type') }}</span>:
              <strong>{{ parseInt(search.circular_type) ? getSearchParamName(search.circular_type, 'cType') : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="4" class="text-right">
              <span>{{ $t('tradeFairConfig.fair_name') }}</span>:
              <strong>{{ parseInt(search.fair_id) ? getSearchFairName(search.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <!-- <pre>{{ finalList }}</pre> -->
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12" v-if="finalList.length > 0">
                    <table style="width:100%;" :key="index" class="mb-4">
                      <thead>
                        <tr>
                          <th>{{ $t('globalTrans.sl_no') }}</th>
                          <th>{{ $t('ditfAppTradeFairManages.application_id') }}</th>
                          <th>{{ $t('ditfAppTradeFairManages.org_name') }}</th>
                          <th>{{ $t('globalTrans.contact_no') }}</th>
                          <th>{{ $t('fair_report.space_category') }}</th>
                          <th>{{ $t('externalTradeFair.refunded_date') }}</th>
                          <th>{{ $t('ditfAppTradeFairManages.cutting_amount') }}</th>
                          <th>{{ $t('externalTradeFair.refunded_amount') }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="finalList.length > 0">
                        <tr v-for="(item, index1) in finalList" :key="index1">
                          <td>{{ $n(index1+1) }}</td>
                          <td>{{ item.app_main?.app_auto_id }}</td>
                          <td>{{ currentLocale === 'bn' ? item.app_main?.comp_name_bn : item.app_main?.comp_name_en }}</td>
                          <td>{{ EngBangNum(item.app_main?.mobile_no.toString()) }}</td>
                          <td>{{ getStallCategoryName(item.stall_cat_id)}}</td>
                          <td>{{ item.refund_date | dateFormat}}</td>
                          <td class="text-right">{{ $n(item.cutting_amount)}}</td>
                          <td class="text-right">{{ $n(item.refund_amount)}}</td>
                        </tr>
                        <tr>
                          <td class="text-right" colspan="7">{{ $t('globalTrans.grand_total') }}</td>
                          <td class="text-right">{{ $n(getTotalRefund()) }}</td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="8" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                        </tr>
                      </tbody>
                    </table>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfRefundReport, getMasterStall } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'
import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    // this.loadData()
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      valid: null,
      baseUrl: internationalTradeFairServiceBaseUrl,
      sortBy: '',
      detailsItemId: '',
      isResult: false,
      priceType: 0,
      itemData: [],
      finalList: [],
      dhakaFairList: [],
      stallList: [],
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 1,
        stall_category: 0,
        start_date: 0,
        end_date: 0,
        stall_id: 0
      },
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.stall_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.finalList = []
        // this.stallList = []
        // this.stallList = this.getStall(newVal)
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  computed: {
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    stallTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getStallCategoryName (id) {
        const Obj = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
        if (this.$i18n.locale === 'bn') {
        return Obj !== undefined ? Obj.text_bn : ''
        } else {
        return Obj !== undefined ? Obj.text_en : ''
        }
    },
    getStallCategoryNames (Ids) {
        const categories = this.stallCategoryList.filter(item => Ids.includes(item.value))

        let cat = '' // Initialize the variable

        categories.forEach((element, index) => {
            // Append the category name based on the current locale
            cat += this.currentLocale === 'en' ? element.text_en : element.text_bn

            // Add a comma and space between names, but not after the last one
            if (index < categories.length - 1) {
                cat += ', '
            }
        })

        return cat
    },
    getTotalRefund () {
      const totalRefundSum = this.finalList.reduce((sum, item) => {
          return sum + parseFloat(item.refund_amount)
      }, 0)

      return totalRefundSum
    },
    getGrandTotalPrice (items) {
      let grandTotal = 0
      items.map((item) => {
        let totalPrice = 0
        item.assigned_stalls.map((itm) => {
          totalPrice += itm.booked_stall?.quoted_price !== undefined ? parseInt(itm.booked_stall?.quoted_price) : 0
        })
        grandTotal += parseInt(totalPrice)
      })
      return grandTotal
    },
    getCountry (id) {
      const sectorObj = this.$store.state.CommonService.commonObj.countryList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    circularType (fair) {
      const sectorObj = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.find(el => el.value === parseInt(fair))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getSearchParamName (Id, paramName) {
      let arrayData
      if (paramName === 'cType') {
        arrayData = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList
      } else {
        return ''
      }
      const Obj = arrayData.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      } else {
        return ''
      }
    },
    getStallName (stallCat) {
        const stall = this.stallList.find(item => item.value === parseInt(stallCat))
        return stall !== undefined ? stall.text : ''
    },
    async getStall (stallCat) {
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, getMasterStall + '/' + stallCat)
      if (result.success) {
        this.stallList = result.data
      } else {
        this.stallList = []
      }
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.isResult = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfRefundReport, this.search)
      if (result.success) {
        this.finalList = result.data
        this.isResult = false
      } else {
        this.isResult = false
        this.finalList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getFloorPrice (catId) {
      const cat = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(item => item.value === catId)
      if (cat !== undefined) {
        this.priceType = cat.price_type
        return cat.floor_price
      } else {
        return 0
      }
    },
    getCompanyTypeName (id) {
      const companyType = this.$store.state.TradeFairService.commonObj.companyTypeList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getStallType (id) {
      const stallType = this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.find(obj => obj.value === parseInt(id))
      if (typeof stallType !== 'undefined') {
        return this.$i18n.locale === 'en' ? stallType.text_en : stallType.text_bn
      } else {
        return ''
      }
    },
    async pdfExport () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 2, year: this.search.year, circular_type: this.search.circular_type, fair_id: this.search.fair_id, stall_category: this.search.stall_category, stall_no: this.search.stall_no, price_type: this.priceType })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, ditfRefundReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
