<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <!-- year -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Year" vid="year" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="year">
                <template v-slot:label>
                  {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="year"
                  v-model="data.year"
                  @keypress="isNumber"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- circular_memo_no -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Circular Memo No." vid="circular_memo_no" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="circular_memo_no">
                <template v-slot:label>
                  {{ $t('teaGardenService.memorial_no') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="circular_memo_no"
                  v-model="data.circular_memo_no"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- circular_title_en -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Circular Name (En)" vid="circular_title_en" rules="">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="circular_title_en">
                <template v-slot:label>
                  {{ $t('teaGardenPduService.circular_name') }} {{ $t('globalTrans.en') }}
                </template>
                <b-form-input
                  id="circular_title_en"
                  v-model="data.title_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- circular_title_bn -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Circular Name (Bn)" vid="circular_title_bn" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="circular_title_bn">
                <template v-slot:label>
                  {{ $t('teaGardenPduService.circular_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="circular_title_bn"
                  v-model="data.title_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- circular_date -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Circular Date" vid="circular_date" rules="required">
              <b-form-group
                class="row"
                label-for="circular_date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('teaGardenPduService.circular_date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="circular_date"
                  v-model="data.circular_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :config="config"
                  :locale="currentLocale"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- application_start_date -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Application Start Date" vid="application_start_date" rules="required">
              <b-form-group
                class="row"
                label-for="application_start_date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('teaGardenPduService.application_start_date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="application_start_date"
                  v-model="data.application_start_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :config="config"
                  :locale="currentLocale"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- application_end_date -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Application End Date" vid="application_end_date" rules="required">
              <b-form-group
                class="row"
                label-for="application_end_date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('teaGardenPduService.application_end_date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="application_end_date"
                  v-model="data.application_end_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :config="config"
                  :locale="currentLocale"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
           <!-- attachment -->
           <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Attachment" vid="attachment" :rules="{required: false}">
              <b-form-group
                class="row"
                label-for="attachment"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('teaGardenPduService.attachment') }}
                </template>
                <div class="d-flex">
                    <b-form-file accept="application/pdf,image/*" id="attachment" v-model="data.attachment"
                      @change="onChange" :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('globalTrans.attachment_placeholder')"
                      :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"></b-form-file>
                      <a target="_blank" style = "margin-left: 8px;" v-if="data.attachment && checkBase64(data.attachment) === false" :href="isImage(data.attachment) ? teaGardenServiceBaseUrl + 'storage' + data.attachment : teaGardenServiceBaseUrl + data.attachment" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                  </div>
                  <div class="invalid-feedback d-block">
                    {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- description_en -->
           <!-- <b-col xs="12" sm="12">
            <ValidationProvider name="Description (En)" vid="description_en">
              <b-form-group
                label-for="description_en">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.description') }} {{ $t('globalTrans.en') }}
                </template>
                <vue-editor
                  id="description_en"
                  v-model="data.description_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col> -->
          <!-- description_bn -->
           <!-- <b-col xs="12" sm="12">
            <ValidationProvider name="Description (Bn)" vid="description_bn">
              <b-form-group
                label-for="description_bn">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.description') }} {{ $t('globalTrans.bn') }}
                </template>
                <vue-editor
                  id="description_bn"
                  v-model="data.description_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col> -->
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { eduTrustSchCricularStore, eduTrustSchCricularUpdate } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
export default {
  name: 'Form',
  props: ['id'],
  components: {
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      valid: null,
      config: { static: true },
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
      },
      course_info: {}
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getCircularList()
      this.data = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    courseList: function () {
      return this.$store.state.TeaGardenService.commonObj.pduCourseList.filter(item => item.status === 1)
    },
    currentLocale () {
        return this.$i18n.locale
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${eduTrustSchCricularUpdate}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, eduTrustSchCricularStore, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    getCircularList () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    onChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.data.attachment = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.data.attachment = ''
        }
      },
      checkBase64 (string) {
          var result = ''
          result = string.match('data:')
          if (result) {
              return true
          } else {
              return false
          }
      },
      isImage (path) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
      }
  },
  watch: {
  }
}
</script>
