<template>
  <card>
    <template v-slot:searchHeaderTitle>
      <h4 class="card-title">{{ $t('teaGardenPduService.online_edducation_app') }} {{ $t('globalTrans.form')}}</h4>
    </template>
    <template v-slot:searchBody>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-overlay :show="loading">
        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
          <b-col sm="12">
            <div class="group-form-card">
              <b-card>
                <b-form-group label-cols-lg="3" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <ValidationProvider name="Trust Type" vid="trust_type">
                          <b-form-group
                            label-cols-sm="2"
                            label-for="trust_type"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            <div style="font-weight: bold">
                              {{ $t('teaGardenPduService.trust_type') }}
                            </div>
                          </template>
                          <b-form-radio-group
                            style="margin-top:5px;"
                            id="trust_type"
                            v-model="formData.trust_type"
                            :disabled="$route.query.id"
                            :options="trustTypeList"
                          ></b-form-radio-group>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
              <!----------------Common start------------->
              <b-card>
                <b-row>
                  <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                    <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-for="year"
                        slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('globalTrans.year')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          disabled
                          @change="checkClassWiseRole()"
                          v-model="formData.year"
                          :options="yearList"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xl="5" lg="5" md="5" sm="12">
                    <ValidationProvider name="Garden" vid="garden_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-for="garden_id"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.garden_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            :disabled="isGardenAdmin"
                            plain
                            v-model="formData.garden_id"
                            :options="gardenList"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                    <!-- fari garden name_en -->
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Fari Tea Garden Name (En)" vid="fari_tea_garden_name_en" rules="">
                        <b-form-group
                          class="row"
                          label-for="fari_tea_garden_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.name_of_outpost_garden') }} {{ $t('globalTrans.en') }}
                          </template>
                          <b-form-input
                            id="fari_tea_garden_name_en"
                            v-model="formData.fari_tea_garden_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- fari garden name_en -->
                  <b-col xl="5" lg="5" md="5" sm="12" >
                      <ValidationProvider name="Fari Tea Garden Name (Bn)" vid="fari_tea_garden_name" rules="">
                        <b-form-group
                          class="row"
                          label-for="fari_tea_garden_name"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.name_of_outpost_garden') }} {{ $t('globalTrans.bn') }}
                          </template>
                          <b-form-input
                            id="fari_tea_garden_name"
                            v-model="formData.fari_tea_garden_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- manager name en-->
                  <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                    <ValidationProvider name="Manager Name (En)" vid="manager_name_en" rules="required">
                        <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="manager_name_en">
                        <template v-slot:label>
                            {{ $t('teaGardenPduService.manager_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="manager_name_en"
                            v-model="formData.manager_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <!-- manager name bn -->
                    <b-col xl="5" lg="5" md="5" sm="12">
                    <ValidationProvider name="Manager Name (Bn)" vid="manager_name_bn" rules="required">
                        <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="manager_name_bn">
                        <template v-slot:label>
                            {{ $t('teaGardenPduService.manager_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="manager_name_bn"
                            v-model="formData.manager_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <!-- Manager mobile -->
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                    <ValidationProvider name="Manager Mobile" vid="manager_mobile" rules="required|min:11|max:11">
                        <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="manager_mobile">
                        <template v-slot:label>
                            {{ $t('teaGardenPduService.manager_mobile') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="manager_mobile"
                            v-model="formData.manager_mobile"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col xl="10" lg="10" md="10" sm="12" v-if="formData.trust_type === 2" offset="1">
                      <ValidationProvider name="Guardian Type" vid="guardian_type" rules="required|min_value:1">
                          <b-form-group
                            label-cols-sm="4"
                            label-for="guardian_type"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            <div style="font-weight: bold">
                              {{ $t('teaGardenPduService.guardian_type') }} <span class="text-danger">*</span>
                            </div>
                          </template>
                          <b-form-radio-group
                            style="margin-top:5px;"
                            id="guardian_type"
                            v-model="formData.guardian_type"
                            :options="guardianType"
                          ></b-form-radio-group>
                          <div class="invalid-feedback d-block">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="10" lg="10" md="10" sm="12" v-if="formData.trust_type === 2" offset="1">
                      <ValidationProvider name="Has Labour Child" vid="is_children_of_tea_workers" rules="required|min_value:1">
                          <b-form-group
                            label-cols-sm="4"
                            label-for="is_children_of_tea_workers"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            <div style="font-weight: bold">
                              {{ $t('teaGardenPduService.is_children_of_tea_workers') }} <span class="text-danger">*</span>
                            </div>
                          </template>
                          <b-form-radio-group
                            style="margin-top:5px;"
                            id="is_children_of_tea_workers"
                            v-model="formData.is_children_of_tea_workers"
                            :options="LabourChildList"
                          ></b-form-radio-group>
                          <div class="invalid-feedback d-block">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                     <!-- Panchayat name en-->
                    <b-col xl="5" lg="5" md="5" sm="12" v-if="formData.trust_type === 2" offset="1">
                    <ValidationProvider name="Panchayat Head Name (En)" vid="panchayat_head_name_en" rules="required">
                        <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="panchayat_head_name_en">
                        <template v-slot:label>
                            {{ $t('teaGardenPduService.panchayat_head_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="panchayat_head_name_en"
                            v-model="formData.panchayat_head_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <!-- Panchayat name bn -->
                    <b-col xl="5" lg="5" md="5" sm="12" v-if="formData.trust_type === 2">
                    <ValidationProvider name="Panchayat Head Name (Bn)" vid="panchayat_head_name_bn" rules="required">
                        <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="panchayat_head_name_bn">
                        <template v-slot:label>
                            {{ $t('teaGardenPduService.panchayat_head_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="panchayat_head_name_bn"
                            v-model="formData.panchayat_head_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <!-- Panchayat mobile bn -->
                    <b-col xl="5" lg="5" md="5" sm="12" v-if="formData.trust_type === 2" offset="1">
                    <ValidationProvider name="Panchayat Head Mobile" vid="panchayat_head_mobile" rules="required|min:11|max:11">
                        <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="panchayat_head_mobile">
                        <template v-slot:label>
                            {{ $t('teaGardenPduService.panchayat_head_mobile') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="panchayat_head_mobile"
                            v-model="formData.panchayat_head_mobile"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                  <b-col xl="5" lg="5" md="5" sm="12">
                    <ValidationProvider name="School" vid="school_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-for="school_id"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.education_institution_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.school_id"
                            :disabled="$route.query.id"
                            :options="schoolList"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <SchoolInfo v-if="formData.school_id > 0" :key="formData.school_id" :schoolInformation="formData"/>
              </b-card>
               <!----------------Common End------------->
              <!----------------Primary start------------->
              <template v-if="formData.trust_type === 1">
                <b-card>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Class Name" vid="class_id" :rules="{required: false}">
                        <b-form-group
                            class="row"
                            label-for="class_id"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{ $t('teaGardenPduService.class_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                @change="checkClassWiseRole()"
                                v-model="primaryDetails.class_id"
                                :options="primaryClassList"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Class Roll Id" vid="roll_id" :rules="{required: false}">
                        <b-form-group
                            class="row"
                            label-for="roll_id"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{ $t('teaGardenPduService.roll_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                :disabled="loadingRoleCheck ? true : false"
                                plain
                                v-model="primaryDetails.roll_id"
                                :options="primaryRollList"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{ loadingRoleCheck ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Student Name (EN)" vid="student_name_en" :rules="{required:false}">
                        <b-form-group
                          class="row"
                          label-for="student_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.student_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="student_name_en"
                            v-model="primaryDetails.student_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Student Name(En)" vid="student_name_bn" :rules="{required: false}">
                        <b-form-group
                          class="row"
                          label-for="student_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.student_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="student_name_bn"
                            v-model="primaryDetails.student_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Guardian Name (EN)" vid="guardian_name_en" :rules="{ required:false }">
                        <b-form-group
                          class="row"
                          label-for="guardian_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.guardian_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="guardian_name_en"
                            v-model="primaryDetails.guardian_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Guardian Name(EN)" vid="guardian_name_bn" :rules="{required: false}">
                        <b-form-group
                          class="row"
                          label-for="guardian_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.guardian_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="guardian_name_bn"
                            v-model="primaryDetails.guardian_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Has Labour Child" vid="has_labour_child" :rules="{required: false}">
                          <b-form-group
                            label-cols-sm="4"
                            label-for="has_labour_child"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            <div style="font-weight: bold">
                              {{ $t('teaGardenPduService.is_labour_child') }} <span class="text-danger">*</span>
                            </div>
                          </template>
                          <b-form-radio-group
                            style="margin-top:5px;"
                            id="has_labour_child"
                            v-model="primaryDetails.has_labour_child"
                            :options="LabourChildList"
                          ></b-form-radio-group>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col>
                      <b-td colspan="4" align="center">
                          <b-button v-if="!primaryDetails.is_edit" @click="addItem()" type="button" variant="success" class="mr-2 mb-2 btn-sm">
                            + {{ $t('globalTrans.add_more')}}
                          </b-button>
                          <b-button v-else @click="editItem(primaryDetails)" type="button" variant="warning" class="mr-2 mb-2 btn-sm">
                            {{ $t('globalTrans.edit')}}
                          </b-button>
                      </b-td>
                    </b-col>
                  </b-row>
                </b-card>
                  <!-- -----------------Add More Primary Section Start------------------- -->
                    <b-card>
                      <b-row>
                        <div class="table-wrapper table-responsive">
                          <table class="table table-striped table-hover table-bordered">
                            <b-thead>
                              <tr>
                                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.class_name') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.roll_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.student_name_status') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.guardian_name_status') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.is_labour_child') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.action') }}</b-th>
                              </tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="formData.details">
                                  <template v-for="(item, index) in formData.details">
                                    <b-tr :key="'p-' + index">
                                      <b-td class="text-center">{{ index+1 }}</b-td>
                                      <b-td class="text-center">{{ getPrimaryClassList(item.class_id) }}</b-td>
                                      <b-td class="text-center">{{ getRollList(item.roll_id) }}</b-td>
                                      <b-td class="text-center">{{ currentLocale === 'en' ? item.student_name_en : item.student_name_bn }}</b-td>
                                      <b-td class="text-center">{{ currentLocale === 'en' ? item.guardian_name_en : item.guardian_name_bn }}</b-td>
                                      <b-td class="text-center">{{ getHasChildList(item.has_labour_child) }}</b-td>
                                      <td class="text-center">
                                        <b-button variant="action-btn edit mr-1" size="sm" :title="$t('globalTrans.delete')" @click="editPrimaryDetails(index)" class="action-btn edit"><i class="ri-pencil-fill"></i></b-button>
                                        <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="removeItem(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                    </td>
                                    </b-tr>
                                  </template>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </table>
                        </div>
                      </b-row>
                      <b-row>
                        <b-col xs="12" sm="12" md="5" lg="5" xl="5" offset="1">
                          <ValidationProvider name="Attachement" vid="attachement" rules="required">
                            <b-form-group
                              slot-scope="{ valid, errors }"
                              label-for="attachement">
                              <template v-slot:label>
                                {{ $t('teaGardenPduService.hand_scan_copy') }} <span class="text-danger">*</span>
                              </template>
                              <b-input-group>
                              <b-form-file
                                id="attachement"
                                name="attachement"
                                v-model="formData.attachement"
                                @change="onChange($event, 'attachement')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <b-input-group-append v-if="formData.attachement && checkBase64(formData.attachement) === false">
                                <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(formData.attachement) ? 'storage/' : '') + formData.attachement" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                              </b-input-group-append>
                              </b-input-group>
                              <span class="text-danger">{{ errMessage.attachement }}</span>
                              <div class="d-block invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-card>
                  <!-- -----------------Add More Primary Section End--------------------- -->
              </template>
              <!------------------Primary End------------->
              <!-------------------Secoundary Start------------->
               <template v-if="formData.trust_type === 2">
                <b-card>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Applicant Name (En)" vid="applicant_name_en" rules="required">
                        <b-form-group
                          class="row"
                          label-for="applicant_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.applicant_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="applicant_name_en"
                            v-model="formData.applicant_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Applicant Name(Bn)" vid="applicant_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-for="applicant_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.applicant_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="applicant_name_bn"
                            v-model="formData.applicant_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Father Name (EN)" vid="father_name_en" rules="required">
                        <b-form-group
                          class="row"
                          label-for="father_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('globalTrans.father_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="father_name_en"
                            v-model="formData.father_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Father Name(En)" vid="father_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-for="father_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('globalTrans.father_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="father_name_bn"
                            v-model="formData.father_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Mother Name (EN)" vid="mother_name_en" rules="required">
                        <b-form-group
                          class="row"
                          label-for="mother_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('globalTrans.mother_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="mother_name_en"
                            v-model="formData.mother_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-for="mother_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('globalTrans.mother_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="mother_name_bn"
                            v-model="formData.mother_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5" offset="1">
                      <ValidationProvider name="Mobile" vid="mobile" rules="required|min:11|max:11">
                        <b-form-group
                          class="row"
                          label-for="mobile"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.parent_mobile') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="mobile"
                            type="number"
                            v-model="formData.mobile_no"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Class" vid="secoundary_class_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-for="secoundary_class_id"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('teaGardenPduService.class_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.secoundary_class_id"
                                :options="secoundaryClassList"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="8" lg="8" md="8" sm="12" offset="1">
                      <ValidationProvider name="Has Labour Child" vid="has_previos_scholarship" rules="required">
                        <b-form-group
                          label-cols-sm="8"
                          label-for="has_previos_scholarship"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          <div style="font-weight: bold">
                            {{ $t('teaGardenPduService.has_previos_scholarship') }} ? <span class="text-danger">*</span>
                          </div>
                        </template>
                        <b-form-radio-group
                          style="margin-top:5px;"
                          id="has_previos_scholarship"
                          v-model="formData.has_previos_scholarship"
                          :options="scholarshipList"
                        ></b-form-radio-group>
                        <div class="invalid-feedback d-block">
                              {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5" offset="1">
                      <ValidationProvider name="Photo" vid="photo">
                        <b-form-group
                          label-for="photo"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('globalTrans.photo') }}
                          </template>
                          <b-input-group>
                            <b-form-file
                              name="photo"
                              accept=".pdf,.jpeg,.jpg"
                              v-model="formData.photo"
                              @change="onChange($event, 'photo')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                            <b-input-group-append v-if="formData.photo && checkBase64(formData.photo) === false">
                              <a target="_blank" :href="teaGardenServiceBaseUrl+(isImage(formData.photo) ? 'storage/' : '') + formData.photo" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                            </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errMessage.photo }}</span>
                          <div class="d-block invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                      <ValidationProvider name="Trust Certificate" vid="trust_certificate">
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="trust_certificate">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.trust_certificate') }}
                          </template>
                          <b-input-group>
                          <b-form-file
                            id="trust_certificate"
                            name="trust_certificate"
                            accept=".pdf,.jpeg,.jpg"
                            v-model="formData.trust_certificate"
                            @change="onChange($event, 'trustCertificate')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <b-input-group-append v-if="formData.trust_certificate && checkBase64(formData.trust_certificate) === false">
                            <a target="_blank" :href="teaGardenServiceBaseUrl+(isImage(formData.trust_certificate) ? 'storage/' : '') + formData.trust_certificate" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                          </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errMessage.trustCertificate }}</span>
                          <div class="d-block invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5" offset="1">
                      <ValidationProvider name="Previous Year Mark Sheet" vid="previous_year_mark_sheet" rules="required">
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="previous_year_mark_sheet">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.previous_year_mark_sheet') }} ({{ $t('teaGardenPduService.first_page') }}) <span class="text-danger">*</span>
                          </template>
                          <b-input-group>
                          <b-form-file
                            id="previous_year_mark_sheet"
                            name="previous_year_mark_sheet"
                            accept=".pdf,.jpeg,.jpg"
                            v-model="formData.previous_year_mark_sheet"
                            @change="onChange($event, 'previousYearMarkSheet')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <b-input-group-append v-if="formData.previous_year_mark_sheet && checkBase64(formData.previous_year_mark_sheet) === false">
                            <a target="_blank" :href="teaGardenServiceBaseUrl+(isImage(formData.previous_year_mark_sheet) ? 'storage/' : '') + formData.previous_year_mark_sheet" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                          </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errMessage.previousYearMarkSheet }}</span>
                          <div class="d-block invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                      <ValidationProvider name="Previous Year Mark Sheet" vid="previous_year_mark_sheet2">
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="previous_year_mark_sheet2">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.previous_year_mark_sheet') }} ({{ $t('teaGardenPduService.second_page') }}) ({{ $t('teaGardenPduService.if_applicable') }})
                          </template>
                          <b-input-group>
                          <b-form-file
                            id="previous_year_mark_sheet2"
                            name="previous_year_mark_sheet2"
                            accept=".pdf,.jpeg,.jpg"
                            v-model="formData.previous_year_mark_sheet2"
                            @change="onChange($event, 'previousYearMarkSheet2')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <b-input-group-append v-if="formData.previous_year_mark_sheet2 && checkBase64(formData.previous_year_mark_sheet2) === false">
                            <a target="_blank" :href="teaGardenServiceBaseUrl+(isImage(formData.previous_year_mark_sheet2) ? 'storage/' : '') + formData.previous_year_mark_sheet2" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                          </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errMessage.previousYearMarkSheet2 }}</span>
                          <div class="d-block invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5" offset="1">
                      <ValidationProvider name="Attachement" vid="attachement" rules="required">
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="attachement">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.hand_scan_copy') }} ({{ $t('teaGardenPduService.first_page') }}) <span class="text-danger">*</span>
                          </template>
                          <b-input-group>
                          <b-form-file
                            id="attachement"
                            name="attachement"
                            accept=".pdf,.jpeg,.jpg"
                            v-model="formData.attachement"
                            @change="onChange($event, 'attachement')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <b-input-group-append v-if="formData.attachement && checkBase64(formData.attachement) === false">
                            <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(formData.attachement) ? 'storage/' : '') + formData.attachement" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                          </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errMessage.attachement }}</span>
                          <div class="d-block invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                      <ValidationProvider name="Attachement 2" vid="attachement2">
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="attachement2">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.hand_scan_copy') }} ({{ $t('teaGardenPduService.second_page') }}) ({{ $t('teaGardenPduService.if_applicable') }})
                          </template>
                          <b-input-group>
                          <b-form-file
                            id="attachement2"
                            name="attachement2"
                            accept=".pdf,.jpeg,.jpg"
                            v-model="formData.attachement2"
                            @change="onChange($event, 'attachement2')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <b-input-group-append v-if="formData.attachement2 && checkBase64(formData.attachement2) === false">
                            <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(formData.attachement2) ? 'storage/' : '') + formData.attachement2" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                          </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errMessage.attachement2 }}</span>
                          <div class="d-block invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
              <!-------------------Secoundary End------------->
              <b-row class="text-right mb-3">
                <b-col>
                  <b-button type="submit" @click="formData.status = 1" variant="info" class="mr-2 btn-sm">{{ $t('globalTrans.save_draft') }}</b-button>
                  <b-button type="submit" @click="formData.status = 2" variant="success" class="mr-2 btn-sm">{{ $t('configuration.final_save') }}</b-button>
                  <router-link :to="{ path: '/tea-garden-service/pdu/online-education-application-list'}" class="btn btn-danger btn-sm">
                      {{ $t('globalTrans.cancel') }}
                  </router-link>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-form>
       </b-overlay>
    </ValidationObserver>
    </template>
  </card>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { eduTrustScholarshipStore, eduTrustScholarshipUpdate, eduTrustScholarshipShow, getGardenSchoolList } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
import SchoolInfo from './SchoolInfo.vue'

export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['item'],
    components: {
        ValidationProvider,
        ValidationObserver,
        SchoolInfo
    },
  data () {
    return {
      valid: null,
      errors: [],
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      errMessage: {
        attachement: '',
        attachement2: '',
        trustCertificate: '',
        previousYearMarkSheet: '',
        previousYearMarkSheet2: '',
        photo: ''
      },
      formData: {
        id: '',
        school_id: 0,
        year: 0,
        status: '',
        fiscal_year_id: '',
        app_id: '',
        trust_type: 1,
        applicant_name_en: '',
        applicant_name_bn: '',
        father_name_en: '',
        father_name_bn: '',
        mother_name_en: '',
        mother_name_bn: '',
        panchayat_head_name_en: '',
        panchayat_head_name_bn: '',
        panchayat_head_mobile: '',
        garden_id: 0,
        circular_id: 0,
        fari_tea_garden_name: '',
        is_children_of_tea_workers: 0,
        school_name_en: '',
        school_name_bn: '',
        head_teacher_name_en: '',
        secoundary_class_id: 0,
        mobile_no: '',
        school_info: false,
        has_previos_scholarship: '',
        photo: '',
        previous_year_mark_sheet: '',
        previous_year_mark_sheet2: '',
        trust_certificate: '',
        attachement: '',
        school_category: 0,
        h_master_name_en: '',
        h_master_name_bn: '',
        h_master_mobile: '',
        area_type_id: '0',
        city_corporation_id: '0',
        pauroshoba_id: '0',
        ward_id: '0',
        country_id: 1,
        division_id: '0',
        district_id: '0',
        upazila_id: '0',
        union_id: '0',
        details: []
      },
      primaryDetails: {
        class_id: 0,
        is_edit: false,
        roll_id: 0,
        student_name_en: '',
        student_name_bn: '',
        guardian_name_en: '',
        guardian_name_bn: '',
        has_labour_child: ''
      },
      primaryRollList: [],
      schoolList: [],
      loading: false,
      formDataKey: 0,
      isGardenAdmin: false,
      loadingRoleCheck: false
    }
  },
  created () {
    if (this.$route.query.circular_id) {
      this.formData.circular_id = this.$route.query.circular_id
      this.formData.year = this.$route.query.year
    }
    if (this.isGardenAdminCheckGardenId()) {
      this.isGardenAdmin = true
      this.formData.garden_id = this.isGardenAdminCheckGardenId()
    }
    this.getPrimaryRollList()
    if (this.$route.query.id) {
      this.$set(this.formData, 'school_info', true)
      this.getEducationTrustEditInfo()
    }
    this.getGardenSchoolList()
  },
  computed: {
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    trustTypeList () {
      return [
        { value: 1, text: this.currentLocale === 'en' ? 'Primary' : 'প্রাথমিক' },
        { value: 2, text: this.currentLocale === 'en' ? 'Secondary' : 'মাধ্যমিক' }
      ]
    },
    guardianType () {
      return [
        { value: 1, text: this.currentLocale === 'en' ? 'Regular Worker' : 'নিয়মিত শ্রমিক' },
        { value: 2, text: this.currentLocale === 'en' ? 'Irregular Worker' : 'অনিয়মিত শ্রমিক' }
      ]
    },
    LabourChildList () {
      return [
        { value: 1, text: this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ' },
        { value: 2, text: this.currentLocale === 'en' ? 'No' : 'না' }
      ]
    },
    scholarshipList () {
      return [
        { value: 1, text: this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ' },
        { value: 2, text: this.currentLocale === 'en' ? 'No' : 'না' }
      ]
    },
    primaryClassList () {
      const list = [
          { value: 1, text: this.currentLocale === 'en' ? 'Two' : '২য়' },
          { value: 2, text: this.currentLocale === 'en' ? 'Three' : '৩য়' },
          { value: 3, text: this.currentLocale === 'en' ? 'Four' : '৪র্থ' },
          { value: 4, text: this.currentLocale === 'en' ? 'Five' : '৫ম' }
      ]
      return list
    },
    secoundaryClassList () {
      const list = [
        { value: 1, text: this.currentLocale === 'en' ? 'Six' : '৬ষ্ঠ' },
        { value: 2, text: this.currentLocale === 'en' ? 'Seven' : '৭ম' },
        { value: 3, text: this.currentLocale === 'en' ? 'Eight' : '৮ম' }
      ]
      return list
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.$n(item, { useGrouping: false }) }
        } else {
          return { value: item, text: item }
        }
      })
    }
  },
  watch: {
    'formData.garden_id': function (newVal, oldValue) {
      if (newVal && newVal !== 0) {
        this.getGardenSchoolList()
        // this.checkClassWiseRole()
      }
    },
    'formData.trust_type': function (newVal, oldValue) {
      if (newVal) {
        this.getGardenSchoolList()
        if (!this.$route.query.id) {
          this.getSchoolInfo()
        }
        if (!this.$route.query.id) {
          this.formData.school_id = 0
        }
      }
    },
    'formData.school_id': function (newVal, oldValue) {
      if (newVal) {
          if (this.formData.trust_type === 1) {
            this.checkClassWiseRole()
          }
        if (!this.$route.query.id) {
          this.getSchoolInfo(newVal)
        }
      }
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.changeEnBn()
        }
    }
  },
  methods: {
    changeEnBn () {
      return this.schoolList.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
    },
    getSchoolInfo (schoolId = 0) {
        const schoolInformation = this.schoolList.find(item => parseInt(item.value) === parseInt(schoolId) && item.value !== 9999)
        if (schoolInformation !== undefined) {
            this.$set(this.formData, 'school_info', true)
            this.$set(this.formData, 'h_master_name_en', schoolInformation.h_master_name_en)
            this.$set(this.formData, 'h_master_name_bn', schoolInformation.h_master_name_bn)
            this.$set(this.formData, 'h_master_mobile', schoolInformation.h_master_mobile)
            this.$set(this.formData, 'school_name_en', schoolInformation.text_en)
            this.$set(this.formData, 'school_name_bn', schoolInformation.text_bn)
            this.$set(this.formData, 'school_category', schoolInformation.school_category)
            this.$set(this.formData, 'school_location', schoolInformation.school_location)
            if (parseInt(this.formData.trust_type) === 2) {
              this.$set(this.formData, 'area_type_id', schoolInformation.area_type_id)
              this.$set(this.formData, 'city_corporation_id', schoolInformation.city_corporation_id)
              this.$set(this.formData, 'pauroshoba_id', schoolInformation.pauroshoba_id)
              this.$set(this.formData, 'ward_id', schoolInformation.ward_id)
              this.$set(this.formData, 'country_id', schoolInformation.country_id)
              this.$set(this.formData, 'division_id', schoolInformation.division_id)
              this.$set(this.formData, 'upazila_id', schoolInformation.upazila_id)
              this.$set(this.formData, 'union_id', schoolInformation.union_id)
            }
        } else {
            this.$set(this.formData, 'school_info', false)
            this.$set(this.formData, 'h_master_name_en', '')
            this.$set(this.formData, 'h_master_name_bn', '')
            this.$set(this.formData, 'h_master_mobile', '')
            this.$set(this.formData, 'school_name_en', '')
            this.$set(this.formData, 'school_name_bn', '')
            this.$set(this.formData, 'school_category', 0)
            this.$set(this.formData, 'school_location', 0)
            if (parseInt(this.formData.trust_type) === 2) {
              this.$set(this.formData, 'area_type_id', '0')
              this.$set(this.formData, 'city_corporation_id', '0')
              this.$set(this.formData, 'pauroshoba_id', '0')
              this.$set(this.formData, 'ward_id', '0')
              this.$set(this.formData, 'country_id', '0')
              this.$set(this.formData, 'division_id', '0')
              this.$set(this.formData, 'upazila_id', '0')
              this.$set(this.formData, 'union_id', '0')
            }
        }
    },
    getPrimaryRollList () {
      this.primaryRollList = [
        { value: 1, text: this.currentLocale === 'en' ? 'One' : '১' },
        { value: 2, text: this.currentLocale === 'en' ? 'Two' : '২' },
        { value: 3, text: this.currentLocale === 'en' ? 'Three' : '৩' }
      ]
    },
    getPrimaryClassList (classId) {
      if (classId === 1) {
        return this.currentLocale === 'en' ? 'Two' : '২য়'
      } else if (classId === 2) {
        return this.currentLocale === 'en' ? 'Three' : '৩য়'
      } else if (classId === 3) {
        return this.currentLocale === 'en' ? 'Four' : '৪র্থ'
      } else if (classId === 4) {
        return this.currentLocale === 'en' ? 'Five' : '৫ম'
      }
    },
    getRollList (rollId) {
      if (rollId === 1) {
        return this.currentLocale === 'en' ? 'One' : '১'
      } else if (rollId === 2) {
        return this.currentLocale === 'en' ? 'Two' : '২'
      } else if (rollId === 3) {
        return this.currentLocale === 'en' ? 'Three' : '৩'
      }
    },
    getHasChildList (childId) {
      if (childId === 1) {
        return this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ'
      } else if (childId === 2) {
        return this.currentLocale === 'en' ? 'No' : 'না'
      }
    },
    async addItem () {
      if (this.primaryDetails.class_id && this.primaryDetails.roll_id && this.primaryDetails.student_name_en && this.primaryDetails.student_name_bn && this.primaryDetails.guardian_name_en &&
          this.primaryDetails.guardian_name_bn && this.primaryDetails.has_labour_child) {
          const objExist = this.formData.details.find(detail => detail.class_id === this.primaryDetails.class_id && detail.roll_id === this.primaryDetails.roll_id)
          if (typeof objExist === 'undefined') {
            this.formData.details.push(this.primaryDetails)
          } else {
            this.$toast.error({
              title: 'Data has already been added',
              color: '#D6E09B'
            })
          }
          this.primaryDetails = {
            is_edit: false,
            class_id: 0,
            roll_id: 0,
            student_name_en: '',
            student_name_bn: '',
            guardian_name_en: '',
            guardian_name_bn: '',
            has_labour_child: ''
          }
          this.$refs.form.reset()
      }
    },
    async editItem (details) {
      if (this.primaryDetails.class_id && this.primaryDetails.roll_id && this.primaryDetails.student_name_en && this.primaryDetails.student_name_bn && this.primaryDetails.guardian_name_en &&
          this.primaryDetails.guardian_name_bn && this.primaryDetails.has_labour_child) {
          const objExist = this.formData.details.find(detail => detail.class_id === this.primaryDetails.class_id && detail.roll_id === this.primaryDetails.roll_id && detail.is_edit === false)

          if (typeof objExist === 'undefined') {
            this.formData.details = this.formData.details.map(item => {
              if (item.is_edit === true) {
                // Replace the item and set `is_edit` to false
                return { ...this.primaryDetails, is_edit: false }
              }
              // Ensure all other items have `is_edit: false`
              return { ...item, is_edit: false }
            })
          } else {
            this.$toast.error({
              title: 'Data has already been added',
              color: '#FFA500'
            })
          }
          this.primaryDetails = {
            class_id: 0,
            is_edit: false,
            roll_id: 0,
            student_name_en: '',
            student_name_bn: '',
            guardian_name_en: '',
            guardian_name_bn: '',
            has_labour_child: ''
          }
          this.$refs.form.reset()
      }
    },
    editPrimaryDetails (key) {
      this.primaryDetails = {
        is_edit: false,
        class_id: 0,
        roll_id: 0,
        student_name_en: '',
        student_name_bn: '',
        guardian_name_en: '',
        guardian_name_bn: '',
        has_labour_child: ''
       }
      const selectedItem = this.formData.details.find((item, index) => index === key)
      selectedItem.is_edit = true
      if (selectedItem) {
        this.primaryDetails = {
          ...selectedItem
        }
      }
    },
    removeItem (index) {
      this.formData.details.splice(index, 1)
    },
    onChange (e, type) {
      const selectedFile = e.target.files[0]
      if (selectedFile.size > 1024 * 1024) {
        e.preventDefault()
        this.errMessage[type] = this.$t('dealer.max_upload')
      } else {
        this.errMessage[type] = ''
        const reader = new FileReader()
        reader.onload = (event) => {
          this.formData[e.target.name] = event.target.result
        }
        reader.readAsDataURL(selectedFile)
      }
    },
    async getEducationTrustEditInfo () {
      this.loading = true
      const result = await RestApi.getData(teaGardenServiceBaseUrl, `${eduTrustScholarshipShow}/${this.$route.query.id}`)
      if (result.success) {
        this.formData = result.data
        this.formData.details = this.formData.details.map(item => ({
          ...item,
          is_edit: false
        }))
        const schoolInformation = result.data.school
        if (schoolInformation !== undefined) {
            this.$set(this.formData, 'school_info', true)
            this.$set(this.formData, 'h_master_name_en', schoolInformation.h_master_name_en)
            this.$set(this.formData, 'h_master_name_bn', schoolInformation.h_master_name_bn)
            this.$set(this.formData, 'h_master_mobile', schoolInformation.h_master_mobile)
            this.$set(this.formData, 'school_name_en', schoolInformation.school_name_en)
            this.$set(this.formData, 'school_name_bn', schoolInformation.school_name_bn)
            this.$set(this.formData, 'school_category', schoolInformation.school_category)
            this.$set(this.formData, 'school_location', schoolInformation.school_location)
            if (parseInt(this.formData.trust_type) === 2) {
              this.$set(this.formData, 'area_type_id', schoolInformation.area_type_id)
              this.$set(this.formData, 'city_corporation_id', schoolInformation.city_corporation_id)
              this.$set(this.formData, 'pauroshoba_id', schoolInformation.pauroshoba_id)
              this.$set(this.formData, 'ward_id', schoolInformation.ward_id)
              this.$set(this.formData, 'country_id', schoolInformation.country_id)
              this.$set(this.formData, 'division_id', schoolInformation.division_id)
              this.$set(this.formData, 'upazila_id', schoolInformation.upazila_id)
              this.$set(this.formData, 'union_id', schoolInformation.union_id)
            }
        }
        // this.getGardenSchoolList()
      } else {
        this.formData = []
      }
      this.loading = false
    },
    async getGardenSchoolList () {
      this.loading = true
      const params = {
        school_type: this.formData.trust_type,
        garden_id: this.formData.garden_id
      }
      const result = await RestApi.getData(teaGardenServiceBaseUrl, getGardenSchoolList, params)
      if (result.success) {
        this.schoolList = result.data
        this.schoolList.unshift({
          value: 9999,
          text_bn: 'কাঙ্ক্ষিত প্রতিষ্ঠান না থাকলে',
          text_en: 'If Institute name not present in list',
          text: this.$i18n.locale === 'bn' ? 'কাঙ্ক্ষিত প্রতিষ্ঠান না থাকলে' : 'If Institute name not present in list'
        })
        // Add extra data at the beginning of the array
      } else {
        this.schoolList.unshift({
          value: 9999,
          text_bn: 'কাঙ্ক্ষিত প্রতিষ্ঠান না থাকলে',
          text_en: 'If Institute name not present in list',
          text: this.$i18n.locale === 'bn' ? 'কাঙ্ক্ষিত প্রতিষ্ঠান না থাকলে' : 'If Institute name not present in list'
        })
        this.schoolList = []
      }
      this.loading = false
    },
    async checkClassWiseRole () {
      this.loadingRoleCheck = true
      const data = Object.assign({}, { year: this.formData.year, garden_id: this.formData.garden_id, trust_type: this.formData.trust_type, class_id: this.primaryDetails.class_id, school_id: this.formData.school_id })
      const result = await RestApi.postData(teaGardenServiceBaseUrl, '/pdu/education-trust-scholarship/application/previous-class-wise-roll', data)
      if (result.success) {
        this.getPrimaryRollList()
        this.primaryRollList = this.primaryRollList.filter(item => {
            return !result.data.includes(item.value)
        })
      } else {
        this.getPrimaryRollList()
      }
      this.loadingRoleCheck = false
    },
    async saveUpdate () {
      if (parseInt(this.formData.status) === 2 && parseInt(this.formData.trust_type) === 1 && this.formData.details.length === 0) {
        this.$toast.success({
          title: this.$t('globalTrans.error'),
          message: this.$t('teaGardenPduService.add_stu'),
          color: '#D6E09B'
        })
        return false
      }
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.$route.query.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${eduTrustScholarshipUpdate}/${this.$route.query.id}`, this.formData)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, eduTrustScholarshipStore, this.formData)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
      this.$router.push({ path: '/tea-garden-service/pdu/online-education-application-list' })
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    checkBase64 (string) {
        var result = ''
        result = string.match('data:')
        if (result) {
            return true
        } else {
            return false
        }
    }
  }
}

</script>
<style scoped>
  .p_heading{
    font-weight: bold;
  }
</style>
