<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loader">
                <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
                    <template v-slot:projectNameSlot>
                    {{ }}
                    </template>
                    {{ $t('globalTrans.refund') }} {{ $t('globalTrans.details') }}
                </list-report-head>
                <b-table-simple v-if="app_info.app_refund.length > 0" bordered small>
                  <thead>
                      <tr>
                          <b-th colspan="7" class="text-center p-2">{{ $i18n.locale == 'bn' ? app_info.comp_name_bn : app_info.comp_name_en }} - {{ app_info.mobile_no | mobileNumber }}</b-th>
                      </tr>
                      <tr class="bg-primary text-center text-align-center">
                          <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                          <th style="width:20%">{{ $t('fair_report.space_category') }}</th>
                          <th style="width:15%">{{ $t('externalTradeFair.refunded_date') }}</th>
                          <th style="width:15%">{{ $t('ditfAppTradeFairManages.cutting_amount') }}</th>
                          <th style="width:15%">{{ $t('externalTradeFair.refunded_amount') }}</th>
                          <th style="width:15%">{{ $t('globalTrans.comments') }}</th>
                          <th style="width:15%">{{ $t('globalTrans.attachment') }}</th>
                      </tr>
                  </thead>
                  <b-tbody>
                      <b-tr v-for="(item, index2) in app_info.app_refund" :key="index2">
                          <b-td class="align-middle text-center">{{ $n(index2+1) }}</b-td>
                          <b-td class="align-middle text-center">{{ getStallCategoryName(item.stall_cat_id) }}</b-td>
                          <b-td class="align-middle text-center">{{ item.refund_date | dateFormat }}</b-td>
                          <b-td class="text-right">{{ $n(item.cutting_amount, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td class="text-right">{{ $n(item.refund_amount, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td class="text-center">{{ item.comments }}</b-td>
                          <b-td class="text-center">
                            <span v-if="item.attachment !== null">
                                <a target="_blank" :href="baseUrl + 'storage/' + item.attachment">
                                    <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                </a>
                            </span>
                            <span v-else>N/A</span>
                          </b-td>
                      </b-tr>
                      <b-tr>
                            <b-td colspan="4" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                            <b-td class="text-right">{{ $n(getTotalRefund(app_info.app_refund), { minimumFractionDigits: 2 }) }}</b-td>
                      </b-tr>
                  </b-tbody>
              </b-table-simple>
            </b-overlay>
        </b-col>
    </b-row>
  </template>
  <script>
 import { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
  import ListReportHead from '@/components/custom/ListReportHead.vue'

  export default {
    name: 'Form',
    props: ['id', 'circular_type'],
    components: {
        ListReportHead
    },
    data () {
        return {
            baseUrl: internationalTradeFairServiceBaseUrl,
            loader: false,
            app_info: {},
            stalls: []
        }
    },
    created () {
        if (this.id) {
            this.app_info = this.getRefundInfo()
        }
    },
    methods: {
        getStallCategoryName (id) {
            const Obj = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            if (this.$i18n.locale === 'bn') {
            return Obj !== undefined ? Obj.text_bn : ''
            } else {
            return Obj !== undefined ? Obj.text_en : ''
            }
        },
        getTotalRefund (items) {
              const totalAmount = items.reduce((total, item) => total + parseFloat(item.refund_amount || 0), 0)
              return totalAmount
          },
        getRefundInfo () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        }
    }
  }
  </script>
