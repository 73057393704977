// All Itf Report Apis......
const itfReport = '/itf-report/'
// Exhibit Info Report
export const exhibitInfoReport = itfReport + 'exhibit-info-report/'
export const exhibitInfoReportList = exhibitInfoReport + 'list'
export const exhibitInfoReportShow = exhibitInfoReport + 'show'

export const fairCalanderInfoReport = itfReport + 'fair-calander-info-report/'
export const fairCalanderInfoReportList = fairCalanderInfoReport + 'list'

// Ledger Report
export const fairLedgerRepostInfo = itfReport + 'fair-ledger-report/'
export const fairLedgerReportList = fairLedgerRepostInfo + 'list'

// Application Report
export const applicationReport = itfReport + 'application-report/'
export const applicationReportList = applicationReport + 'list'
export const applicationReportShow = applicationReport + 'show'

// All DITF Report Apis......
const ditfReport = '/ditf-report/'

// DITF Application Report
export const ditfApplicationReport = ditfReport + 'application-report/list'

export const ditfApplicationTransReport = ditfReport + 'applicant-transaction-report'

// DITF stall Booked Report
export const ditfStallBookedReport = ditfReport + 'stall-book-report/list'

// DITF stall Wise Bidding Report
export const ditfStallWiseBiddingReport = ditfReport + 'stall-wise-bidding-report/list'

// DITF Bidding Report
export const ditfBiddingReport = ditfReport + 'bidding-report/list'
export const ditfRefundReport = ditfReport + 'refund-report/list'
export const getMasterStall = ditfReport + 'bidding-report/get-stall'
export const getMasterStalls = ditfReport + 'bidding-report/get-all-stalls'

// DITF Scrutiny report
export const ditfScrutinyReport = ditfReport + 'scrutiny-report/list'
export const companySelectionReport = ditfReport + 'company-selection-report/list'

// Ledger Report
export const ditfLedgerReportInfo = ditfReport + 'applicant-ledger-report/list'

// DITF Bank Report
export const ditfBankReport = ditfReport + 'bank-report/list'

// DITF Balance Sheet Report
export const ditfBalanceSheetReport = ditfReport + 'balance-sheet-report'

// DITF Balance Sheet Report
export const retrunSecurityMoneyReport = ditfReport + 'return-security-money-report'

// DITF Forfeiture Report
export const ditfSecurityForfeitureReport = ditfReport + 'security-forfeiture-report'
