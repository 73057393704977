<template>
    <div>
        <table style="width: 100%;" class="table table-bordered">
            <tr>
                <td>{{ $t('globalTrans.year')}}</td>
                <td>:</td>
                <td>{{ $n(formData.year , {useGrouping: false})}}</td>
            </tr>
            <tr>
                <td>{{ $t('teaGardenPduService.circular_name')}}</td>
                <td>:</td>
                <td>{{ currentLocale == 'bn' ? formData.title_bn : formData.title_en }}</td>
            </tr>
            <tr>
                <td>{{ $t('teaGardenPduService.circular_date')}}</td>
                <td>:</td>
                <td>{{ formData.circular_date | dateFormat }}</td>
            </tr>
            <tr>
                <td>{{ $t('teaGardenPduService.application_start_date')}}</td>
                <td>:</td>
                <td>{{ formData.application_start_date | dateFormat }}</td>
            </tr>
            <tr>
                <td>{{ $t('teaGardenPduService.application_end_date')}}</td>
                <td>:</td>
                <td>{{ formData.application_end_date | dateFormat }}</td>
            </tr>
            <tr>
                <td>{{ $t('teaGardenPduService.circular')}}</td>
                <td>:</td>
                <td>
                    <template class="pb-3" v-if="formData.attachment && isFile(formData.attachment)">
                      <a :href="teaGardenServiceBaseUrl + (isImage(formData.attachment) ? 'storage/' : '') + formData.attachment" target="_blank" :title="$t('globalTrans.download')" class="btn btn-sm btn-success action-btn" download> {{ $t('globalTrans.download') }} <i class="ri-download-cloud-line"></i></a>
                    </template>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
  import { teaGardenServiceBaseUrl } from '@/config/api_config'

  export default {
      name: 'Details',
      props: ['id'],
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
              formData: {
              }
          }
      },
      created () {
        if (this.id) {
            const tmp = this.getCircularList()
            this.formData = tmp
        }
      },
      mounted () {
      },
      computed: {
          stepList () {
              const arr1 = [
                  { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
                  { value: 2, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
                  { value: 3, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
                  { value: 4, text: this.$i18n.locale === 'en' ? 'Closed' : 'সমাপ্তি', text_en: 'Closed', text_bn: 'সমাপ্তি' }
              ]
              const tcbStepList = arr1
              return tcbStepList
          },
          currentLocale () {
              return this.$i18n.locale
          },
          loading () {
              return this.$store.state.commonObj.loading
          },
          forwardStatusList () {
              const list = [
                  { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
                  { value: 2, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
                  { value: 3, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' }
              ]
              return list
          }
      },
      methods: {
        getCircularList () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        back () {
            this.$router.go(-1)
        },
        isImage (path) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
        },
        isFile (path) {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg|docs|doc|pdf|csv|xsl|xslx|ppt|pptx)$/.test(path)
        }
      }
  }
</script>
