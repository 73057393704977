<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenPduService.forward_list') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="fiscal_year_id"
            >
            <template v-slot:label>
              {{ $t('globalTrans.fiscal_year') }}
            </template>
            <b-form-select
                plain
                v-model="search.fiscal_year_id"
                :options="fiscalYearList"
                id="fiscal_year_id"
                >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            </b-form-group>
          </b-col> -->
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <b-form-group
                label-for="year">
                <template v-slot:label>
                  {{ $t('globalTrans.year') }}
                </template>
                <b-form-input
                  id="year"
                  v-model="search.year"
                  @keypress="isNumber"
                ></b-form-input>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-form-group label-for="application_date">
                <template v-slot:label>
                  {{ $t('globalTrans.application_date') }}
                </template>
                <date-picker
                  id="application_date"
                  class="form-control"
                  v-model="search.application_date"
                  :placeholder="$t('globalTrans.select')"
                  :locale="currentLocale"
                >
                </date-picker>
              </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title"> {{ $t('teaGardenPduService.forward_list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
              <div class="quick-filter-wrapper">
                <div class="quick-filter-left">
                  <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                    <b-dropdown-form>
                      <div
                        class="form-group"
                        v-for="(field, index) in labelData"
                        :key="index"
                        >
                          <b-form-checkbox
                            :id="'checkbox-' + field.labels"
                            v-model="field.show"
                            :name="'checkbox-' + field.labels"
                            value=1
                            unchecked-value=0
                          >
                            {{ $t(field.labels)  }}
                          </b-form-checkbox>
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
                <div class="quick-filter-right">
                  <b-form-group
                    :label="$t('menu.perpage')"
                    label-for="per-page-select"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="search.limit"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(year)="data">
                    {{ $n(data.item.year, {useGrouping: false}) }}
                  </template>
                  <template v-slot:cell(trust_type)="data">
                    {{ getTrustTypeList(data.item.trust_type) }}
                  </template>
                  <template v-slot:cell(application_date)="data">
                    {{ data.item.application_date | dateFormat }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <!-- <span class="badge badge-warning" v-if="data.item.status === 1">{{ $t('globalTrans.save_draft') }}</span>
                    <span class="badge badge-success" v-else-if="data.item.status === 2">{{ $t('configuration.final_save') }}</span>
                    <span class="badge badge-info" v-else-if="data.item.status === 3">{{ $t('teaGardenPduService.assign') }}</span>
                    <span class="badge badge-info" v-else-if="data.item.status === 4">{{ $t('component_settings.forward') }}</span>
                    <span class="badge badge-success" v-else-if="data.item.status === 5">{{ $t('globalTrans.approved') }}</span>
                    <span class="badge badge-danger" v-else-if="data.item.status === 6">{{ $t('globalTrans.rejected') }}</span> -->
                    <span class="badge badge-info">{{$t('globalTrans.forwarded_to')}} {{ forwardedDesignationName(data.item.forwards[0]) }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <!----Application Details Button-->
                    <b-button class="moc-action-btn moc-view-btn" v-if="data.item.status === 2 || data.item.status === 3 || data.item.status === 4" v-b-modal.modal-details variant="iq-bg-success mr-1" size="sm" @click="details(data.item)" :title="$t('globalTrans.details')">
                      <i class="ri-eye-line"></i>
                    </b-button>
                    <!---Application Approve Button--->
                    <b-button class="moc-action-btn moc-approve-btn" v-if="data.item.status === 4 && ($store.state.Auth.authUser.org_admin === 2 || $store.state.Auth.activeRoleId === 1)" @click="approve(data.item)" :title="$t('globalTrans.approve')">
                      <i class="ri-check-line"></i>
                    </b-button>
                    <!---Application Reject Button--->
                    <b-button class="moc-action-btn moc-reject-btn" v-if="data.item.status === 4  && ($store.state.Auth.authUser.org_admin === 2 || $store.state.Auth.activeRoleId === 1)" @click="reject(data.item)" :title="$t('globalTrans.reject')">
                      <i class="ri-close-line"></i>
                    </b-button>
                    <!---Forward Button-->
                    <b-button class="moc-action-btn moc-forward-btn" v-if="data.item.status === 4" :title="$t('globalTrans.forward')" v-b-modal.modal-5 @click="forward(data.item)">
                      <i class="ri-arrow-go-forward-fill"></i>
                    </b-button>
                     <!----Application Forward History Details-->
                    <b-button class="moc-action-btn moc-view-btn" v-if="data.item.status === 4" v-b-modal.modal-6 variant="iq-bg-success mr-1" size="sm" @click="forwardDetails(data.item)" :title="$t('teaGardenPduService.forward_history')">
                      <i class="ri-at-line"></i>
                    </b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('globalTrans.forward')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Forward :id="forwardItemId"/>
    </b-modal>
    <b-modal id="modal-6" size="lg" :title="forwardHistoryTitile" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <ForwardDetails :items="items" :viewitemId="viewitemId"/>
    </b-modal>
  </div>
</template>
<script>
import { helpers } from '@/utils/helper-functions'
import Details from '../common/Details.vue'
import Forward from './Forward.vue'
import ForwardDetails from './ForwardDetails.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { eduTrustForwardList, eduTrustForwardApprove, eduTrustForwardReject } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details, Forward, ForwardDetails
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      viewitemId: '',
      forwardItemId: '',
      items: '',
      search: {
        fiscal_year_id: 0,
        application_date: '',
        limit: 20
      },
      districtList: [],
      schoolNameList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
        { labels: 'globalTrans.year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'teaGardenPduService.trust_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'approvalProcess.app_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'teaGardenPanel.application_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 7, thStyle: { width: '12%' } }
      ]
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'year' },
          { key: 'trust_type' },
          { key: 'app_id' },
          { key: 'application_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'year' },
          { key: 'trust_type' },
          { key: 'app_id' },
          { key: 'application_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('teaGardenConfig.tea_garden_school') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGardenConfig.tea_garden_school') + ' ' + this.$t('globalTrans.modify')
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    viewTitle () {
      return this.$t('globalTrans.details')
    },
    forwardHistoryTitile () {
      return this.$t('teaGardenPduService.forward_history')
    }
  },
  methods: {
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    details (item) {
      this.viewitemId = item.id
      this.items = item
    },
    forwardDetails (item) {
      this.viewitemId = item.id
      this.items = item
    },
    forward (item) {
      this.forwardItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, eduTrustForwardList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
        const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === parseInt(item.fiscal_year_id))
        const ficalYearData = {}
        if (typeof fiscalYearObj !== 'undefined') {
          ficalYearData.fiscal_year_en = fiscalYearObj.text_en
          ficalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          ficalYearData.fiscal_year_en = ''
          ficalYearData.fiscal_year_bn = ''
        }
        return Object.assign({}, item, { serial: index }, ficalYearData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
    },
    getTrustTypeList (type) {
      if (type === 1) {
        return this.currentLocale === 'en' ? 'Primary' : 'প্রাথমিক'
      } else if (type === 2) {
        return this.currentLocale === 'en' ? 'Secondary' : 'মাধ্যমিক'
      }
    },
    approve (item) {
      window.vm.$swal({
      title: window.vm.$t('teaGardenPduService.approve_status'),
      showCancelButton: true,
      confirmButtonText: window.vm.$t('globalTrans.yes'),
      cancelButtonText: window.vm.$t('globalTrans.no'),
      focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.approveSubmit(item)
        }
      })
    },
    approveSubmit (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.deleteData(teaGardenServiceBaseUrl, `${eduTrustForwardApprove}/${item.id}`).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: window.vm.$t('globalTrans.success'),
            message: window.vm.$t('globalTrans.update_msg'),
            color: '#D6E09B'
          })
        } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    reject (item) {
      window.vm.$swal({
      title: window.vm.$t('teaGardenPduService.reject_status'),
      showCancelButton: true,
      confirmButtonText: window.vm.$t('globalTrans.yes'),
      cancelButtonText: window.vm.$t('globalTrans.no'),
      focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.rejectSubmit(item)
        }
      })
    },
    rejectSubmit (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.deleteData(teaGardenServiceBaseUrl, `${eduTrustForwardReject}/${item.id}`).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: window.vm.$t('globalTrans.success'),
            message: window.vm.$t('globalTrans.update_msg'),
            color: '#D6E09B'
          })
        } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getSubDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.subDesignationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    forwardedDesignationName (forward) {
      if (forward.sub_designation_id) {
        return this.getSubDesignationName(forward.sub_designation_id)
      } else if (forward.designation_id) {
        return this.getDesignationName(forward.designation_id)
      }
    }
  }
}
</script>
