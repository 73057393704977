<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenPduService.circular') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <b-form-group
                label-for="year">
                <template v-slot:label>
                  {{ $t('globalTrans.year') }}
                </template>
                <b-form-input
                  id="year"
                  v-model="search.year"
                  @keypress="isNumber"
                ></b-form-input>
              </b-form-group>
          </b-col>
          <!-- circular name -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="circular_title"
            >
              <template v-slot:label>
                {{ $t('teaGardenPduService.circular_name') }}
              </template>
              <b-form-input
                  id="circular_title"
                  type="text"
                  v-model="search.title"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title"> {{ $t('teaGardenPduService.circular') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
                <div class="quick-filter-wrapper">
                  <div class="quick-filter-left">
                    <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                      <b-dropdown-form>
                        <div
                          class="form-group"
                          v-for="(field, index) in labelData"
                          :key="index"
                          >
                            <b-form-checkbox
                              :id="'checkbox-' + field.labels"
                              v-model="field.show"
                              :name="'checkbox-' + field.labels"
                              value=1
                              unchecked-value=0
                            >
                              {{ $t(field.labels)  }}
                            </b-form-checkbox>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                  <div class="quick-filter-right">
                    <b-form-group
                      :label="$t('menu.perpage')"
                      label-for="per-page-select"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="search.limit"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(year)="data">
                    <span>{{ $n(data.item.year, { useGrouping: false}) }}</span>
                  </template>
                  <template v-slot:cell(start_date)="data">
                    <span>{{ data.item.application_start_date | dateFormat }}</span>
                  </template>
                  <template v-slot:cell(end_date)="data">
                    <span>{{ data.item.application_end_date | dateFormat }}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                      <span :class="'badge badge-'+ getStatusInfo(data.item, 2)">{{ getStatusInfo(data.item) }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button  v-b-modal.modal-details variant="iq-bg-success mr-1" size="sm" @click=" editMethodInParent(data.item, 1)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"> <i class="ri-eye-line"></i></b-button>
                    <b-button
                      v-if="(new Date(data.item.application_start_date) <= new Date(today)) &&
                            (new Date(data.item.application_end_date) >= new Date(today))"
                      @click="apply(data.item)"
                      class="btn btn-success">
                      {{ $t('teaGardenPduService.apply_for_scholarship') }}
                    </b-button>

                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-details" size="lg" :title="this.$t('teaGardenPduService.circular') + ' ' + this.$t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId"/>
    </b-modal>
  </div>
</template>
<script>
import Details from './Details.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { eduTrustSchCricularList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import { helpers } from '@/utils/helper-functions'
const today = new Date().toISOString().split('T')[0]
export default {
  mixins: [ModalBaseMasterList],
  components: { Details },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      today: today,
      sortDirection: 'desc',
      sortDesc: true,
      service_id: 0,
      search: {
        circular_title: '',
        status: 2,
        limit: 20
      },
      forwardedItemId: '',
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
        { labels: 'globalTrans.year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: 'auto' } },
        { labels: 'teaGardenPduService.circular_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: 'auto' } },
        { labels: 'teaGardenPduService.application_start_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'teaGardenPduService.application_end_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 9, thStyle: { width: '12%' } }
      ],
      actions: {
        edit: true,
        toogle: true,
        delete: false
      },
      item: {
        id: 0
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'year' },
          { key: 'title_bn' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'year' },
          { key: 'title_en' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('teaGardenPduService.circular') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGardenPduService.circular') + ' ' + this.$t('globalTrans.modify')
    }
  },
  methods: {
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    getStatusInfo (item, type = 1) {
      let statusText = this.$t('globalTrans.draft')
      let statusClass = 'light'
      if (item.status === 1) {
        statusText = this.$t('globalTrans.pending')
        statusClass = 'warning'
      } else if (item.status === 2) {
        statusText = this.$t('globalTrans.published')
        statusClass = 'success'
      }
      return type === 2 ? statusClass : statusText
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      }
    },
    apply (item) {
      this.$router.push({
        name: 'tea_garden_service.pdu.online_education_application_form',
        query: { circular_id: item.id, year: item.year }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, eduTrustSchCricularList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
